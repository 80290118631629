import { __decorate } from "tslib";
import { Component, Vue, Ref, Emit, } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import { StaffSelector } from '@cloudpivot-hermes/common-components';
import moment from 'moment';
import { FlowService } from '@/sevices';
let FilterFlowInstane = class FilterFlowInstane extends Vue {
    constructor() {
        super(...arguments);
        this.workflowTemList = [];
        this.rangeDate = [];
        this.staffSelectorValue = [];
        this.form = {
            startTime: '',
            endTime: '',
            originator: '',
            workflowCode: undefined,
            instanceName: '',
        };
        this.rules = [];
    }
    onChange(date) {
        let startMomentTime = '';
        let endMomentTime = '';
        if (date.length) {
            const startTime = date[0] || {};
            const endTime = date[1] || {};
            startMomentTime = moment(startTime).format('YYYY-MM-DD HH:mm:ss');
            endMomentTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss');
        }
        this.form.startTime = startMomentTime;
        this.form.endTime = endMomentTime;
    }
    onChangeStaff(staffSelectorValue) {
        this.form.originator =
            staffSelectorValue.length && staffSelectorValue[0].id;
    }
    async getWorkflowTree() {
        const res = await FlowService.getWorkflowTree();
        if (res.success) {
            this.workflowTemList = res.data || [];
        }
    }
    reset() {
        this.form.startTime = '';
        this.form.endTime = '';
        this.form.originator = '';
        this.form.workflowCode = undefined;
        this.form.instanceName = '';
        this.rangeDate = [];
    }
    created() {
        this.getWorkflowTree();
    }
    search() {
        return this.form;
    }
};
__decorate([
    Ref()
], FilterFlowInstane.prototype, "cform", void 0);
__decorate([
    Emit('search')
], FilterFlowInstane.prototype, "search", null);
FilterFlowInstane = __decorate([
    Component({
        name: 'FilterFlowInstane',
        components: {
            CForm: Form,
            CFormItem: FormItem,
            StaffSelector,
        },
    })
], FilterFlowInstane);
export default FilterFlowInstane;
